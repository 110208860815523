export const MEDIA_FILE = 'mediaFile';
export const MEDIA_VIDEO = 'mediaVideo';
export const MEDIA_URL = 'mediaUrl';

export const ENABLE_BANNER = 'enableBanner';
export const ENABLE_MEDIA = 'enableMedia';
export const BANNER_URL_LINK = 'bannerUrlLink';

export const HERO_TITLE = 'title';
export const HERO_TITLE_COLOR = 'titleColor';

export const PRE_TITLE = 'preTitle';
export const PRE_TITLE_COLOR = 'preTitleColor';

export const PARAGRAPH = 'paragraph';
export const PARAGRAPH_COLOR = 'paragraphColor';

export const CTA = 'cta';
export const CTA_TYPE = 'ctaType';

export const LEGAL_TEXT = 'legalText';
export const BANNER_POP_UP_TITLE = 'bannerPopUpTitle';
export const BANNER_POP_UP_BODY = 'bannerPopUpBody';
export const LEGAL_TEXT_COLOR = 'legalTextColor';

export const TEXT_ALIGN = 'textAlign';
export const HEADER_TYPE = 'headerType';
export const URL_LEGAL_LINK = 'urlLegaLink';

export const HERO_TITLE_MAX_LENGTH = 40;
export const PRE_TITLE_MAX_LENGTH = 25;
export const PARAGRAPH_MAX_LENGTH = 80;
export const LEGAL_TEXT_MAX_LENGTH = 100;
export const CTA_MAX_LENGTH = 20;

export const hiddenButonsWYSWYGComponent = [
  'source',
  'strikethrough',
  'italic',
  'eraser',
  'superscript',
  'subscript',
  'ul',
  'ol',
  'outdent',
  'indent',
  'font',
  'fontsize',
  'brush',
  'paragraph',
  'image',
  'file',
  'video',
  'table',
  'link',
  'align',
  'undo',
  'redo',
  'selectall',
  'cut',
  'copy',
  'paste',
  'copyformat',
  'left',
  'hr',
  'br',
  'symbol',
  'fullsize',
  'print',
  'preview',
  'find',
  'about',
  'dots',
];
