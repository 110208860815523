import { useState, useCallback } from 'react';

interface UseFieldTouchedProps {
  isSubmited: boolean;
}

export const useFieldTouched = ({ isSubmited }: UseFieldTouchedProps) => {
  const [touchedFields, setTouchedFields] = useState<{
    [key: string]: boolean;
  }>({});

  const markFieldAsTouched = useCallback((fieldKey: string) => {
    setTouchedFields((prev) => ({ ...prev, [fieldKey]: true }));
  }, []);

  const isFieldTouchedOrFormSubmitted = useCallback(
    (fieldKey: string) => touchedFields[fieldKey] || isSubmited,
    [touchedFields, isSubmited],
  );

  return {
    markFieldAsTouched,
    isFieldTouchedOrFormSubmitted,
  };
};
