import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';
import * as api from '../../api';
import apiPaths from '../../apiPaths';
import { ITableRenderListProps } from '../../tables/TableRender';
import { EditFormRenderProps } from '../../forms/edit/EditFormRender';
import { SearchFormRenderProps } from '../../forms/search/SearchFormRender';
import { isTableProps } from '../../utils/propsUtils';
import { useIntl } from 'react-intl';

export default function DeleteCustomerCartItems<
  T extends ITableRenderListProps | EditFormRenderProps | SearchFormRenderProps,
>(parentProps: T) {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  if (isTableProps(parentProps)) {
    const tableProps: ITableRenderListProps = parentProps;
    const { selectedRow, isLoading } = tableProps.props;
    const userSelected = selectedRow?.customerId;

    const handleRemoveCartItems = async (isSpecificUser: boolean) => {
      setLoading(true);
      try {
        const response = await api.deleteDataCallById({
          dataPath: apiPaths.CALL.CUSTOMER_REMOVE_CART_ITEMS,
          registerId: isSpecificUser ? userSelected : '',
        });

        if (response?.status === 200 && response?.data === []) {
          await tableProps.handleReloadData();
        }

        if (response?.data.length) {
          notification.error({
            message: `${response.data[0].message}`,
            duration: 3,
          });
        }

        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    const openConfirmDelete = (isSpecificUser: boolean = false) => {
      Modal.confirm({
        title: formatMessage({
          id: 'pop.title.remove-cart-items',
        }),
        okText: formatMessage({ id: 'pop.accept' }),
        cancelText: formatMessage({ id: 'pop.cancel' }),
        maskClosable: true,
        onOk() {
          handleRemoveCartItems(isSpecificUser);
        },
        onCancel() {},
      });
    };

    return (
      <>
        {/* <Button
          style={{ marginRight: '12px' }}
          onClick={() => openConfirmDelete()}
          loading={isLoading || loading}
          type="primary">
          {formatMessage({
            id: 'custom-buttons.remove-users-cart-items',
          })}
        </Button> */}
        {userSelected && (
          <Button
            style={{ marginRight: '12px' }}
            onClick={() => openConfirmDelete(true)}
            loading={isLoading || loading}
            type="primary">
            {formatMessage({
              id: 'custom-buttons.remove-user-cart-items',
            })}
          </Button>
        )}
      </>
    );
  }
  return <></>;
}
