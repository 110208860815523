import { Button, Checkbox, Icon, Input, Radio, Select } from 'antd';
import React, { FC, Fragment, useState } from 'react';

import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import { useSelector } from 'react-redux';
import UploadComponent from '../../../../challenge/Components/UploadComponent';
import {
  typesOfSelectEnum,
  UPLOAD_ACTION_IMAGE,
  UPLOAD_ACTION_VIDEO,
} from '../../../../shared';
import FormItem from 'antd/lib/form/FormItem';
import {
  ENABLE_BANNER,
  ENABLE_MEDIA,
  hiddenButonsWYSWYGComponent,
  MEDIA_URL,
} from './constants';
import { isValidUrl } from './validate.fields';

import './WebeatHeroSections.css';
import { useWebeatHomeSectionContext } from '../../WebeatHomeSectionFields';
import WYSWYGComponent from '../../../../contentPage/components/WYSWYGComponent';
import { useFieldTouched } from '../../hooks/useFileTouched';
import { removeHtmlTags } from '../../utils';

export const RenderHeroFields: FC<{
  handleChange: (key: string, value: any) => void;
  fieldConfig: {
    key: string;
    label: string;
    subLabel?: string;
    type: string;
    placeholder: string;
    required?: boolean;
    maxLength?: number;
  };
  value: any;
  contentState?: any;
  homeId?: string;
  parentProps: any;
}> = ({
  handleChange,
  fieldConfig,
  value,
  contentState,
  homeId,
  parentProps,
}) => {
  const { auth } = useSelector((state: any) => state);

  const context = useWebeatHomeSectionContext();

  const [, forceUpdate] = useState<number>(0);

  const { markFieldAsTouched, isFieldTouchedOrFormSubmitted } = useFieldTouched(
    { isSubmited: parentProps.state.isHomeFormSubmited },
  );

  // Error length handling
  let error;
  if (fieldConfig?.maxLength) {
    error = removeHtmlTags(value)?.length > fieldConfig?.maxLength!;
  }

  if (fieldConfig.type === 'media-banner-selector') {
    if (
      fieldConfig.key === ENABLE_BANNER &&
      value === true &&
      contentState[ENABLE_MEDIA] === true
    ) {
      handleChange(ENABLE_BANNER, true);
      handleChange(ENABLE_MEDIA, false);
    }

    if (
      (fieldConfig.key === ENABLE_BANNER &&
        (value === null || value === undefined)) ||
      (fieldConfig.key === ENABLE_MEDIA &&
        (value === null || value === undefined))
    ) {
      handleChange(ENABLE_BANNER, true);
      handleChange(ENABLE_MEDIA, false);
    }

    return (
      <>
        {fieldConfig.key === ENABLE_BANNER && (
          <div className="webeat-home-section-hero-spacer"></div>
        )}
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.checked)}>
          {fieldConfig.label}
        </Checkbox>
      </>
    );
  }

  if (fieldConfig.type === 'url-input') {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>

        <FormItem
          style={{
            maxWidth: 400,
          }}
          colon={false}
          validateStatus={
            !value && isFieldTouchedOrFormSubmitted(fieldConfig.key)
              ? 'error'
              : ''
          }
          required={fieldConfig.required}
          help={
            !value &&
            isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            'The URL format is required.'
          }>
          <Input
            onBlur={() => markFieldAsTouched(fieldConfig.key)}
            value={value}
            onChange={(e) => {
              handleChange(fieldConfig.key, e.target.value);
            }}
            placeholder={fieldConfig.placeholder}
          />
        </FormItem>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'input' && contentState[ENABLE_BANNER]) {
    return (
      <FormItem
        style={{
          maxWidth: 400,
        }}
        className="webeat-home-section-hero-input"
        colon={false}
        validateStatus={
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) && error) ||
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            fieldConfig.required &&
            !value)
            ? 'error'
            : ''
        }
        required={fieldConfig.required}
        help={
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            error &&
            `It should contain a maximum caracters of: ${fieldConfig?.maxLength}.`) ||
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            fieldConfig.required &&
            !value &&
            `It should required.`)
        }>
        <Input
          value={value}
          onBlur={() => markFieldAsTouched(fieldConfig.key)}
          onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
          placeholder={fieldConfig.placeholder}
        />
      </FormItem>
    );
  }

  if (fieldConfig.type === 'wyswyg' && contentState[ENABLE_BANNER]) {
    return (
      <FormItem
        style={{
          maxWidth: 800,
          marginBottom:
            (isFieldTouchedOrFormSubmitted(fieldConfig.key) && error) ||
            (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
              fieldConfig.required)
              ? '10px'
              : '',
        }}
        className="webeat-home-section-hero-input"
        colon={false}
        validateStatus={
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) && error) ||
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            fieldConfig.required &&
            !value)
            ? 'error'
            : ''
        }
        required={fieldConfig.required}
        help={
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            error &&
            `It should contain a maximum caracters of: ${fieldConfig?.maxLength}.`) ||
          (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
            fieldConfig.required &&
            !value &&
            `It should required.`)
        }>
        <div
          onBlur={() => markFieldAsTouched(fieldConfig.key)}
          style={{
            border:
              (isFieldTouchedOrFormSubmitted(fieldConfig.key) && error) ||
              (isFieldTouchedOrFormSubmitted(fieldConfig.key) &&
                fieldConfig.required &&
                !value)
                ? '1px solid red'
                : '',
          }}>
          <WYSWYGComponent
            height={400}
            key={fieldConfig.key}
            hiddenButtons={hiddenButonsWYSWYGComponent}
            onChange={(e) => {
              handleChange(
                fieldConfig.key,
                (e.target as HTMLInputElement).value,
              );
            }}
            value={value}
          />
        </div>
      </FormItem>
    );
  }

  if (fieldConfig.type === 'checkbox' && contentState[ENABLE_BANNER]) {
    return (
      <>
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(fieldConfig.key, e.target.checked)}>
          {fieldConfig.label}
        </Checkbox>
      </>
    );
  }

  if (fieldConfig.type === 'title' && contentState[ENABLE_BANNER]) {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'title-media') {
    return (
      <Fragment>
        <div className="webeat-home-section-hero-spacer"></div>
        <Title level={3}>{fieldConfig.label}</Title>
        <Text>{fieldConfig.subLabel}</Text>
      </Fragment>
    );
  }

  if (fieldConfig.type === 'upload') {
    const DUMMY_IMAGE_UUID = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384f';
    const DUMMY_IMAGE_UUID2 = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384c';
    const DUMMY_IMAGE_UUID3 = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384g';
    if (
      value === undefined ||
      (value === null && contentState[MEDIA_URL].checked === null) ||
      (value === undefined && contentState[MEDIA_URL].checked === undefined)
    ) {
      value = {
        checked: true,
        desktopLarge: null,
        desktop: null,
        mobile: null,
      };
    }

    return (
      <Fragment>
        <br />
        <Checkbox
          checked={value['checked']}
          onChange={(e) =>
            handleChange(fieldConfig.key, {
              desktopLarge: value.desktopLarge,
              checked: e.target.checked ? true : false,
              desktop: value.desktop,
              mobile: value.mobile,
            })
          }>
          {fieldConfig.label}
        </Checkbox>
        <br />
        {value['checked'] &&
          [
            {
              value: 'desktopLarge',
              label: 'Desktop Large',
              placeholder: 'Desktop Large',
              resolution: '2560x748 pixels.',
              imageUUID: DUMMY_IMAGE_UUID,
            },
            {
              value: 'desktop',
              label: 'Desktop',
              placeholder: 'Desktop',
              resolution: '1440x709 pixels.',
              imageUUID: DUMMY_IMAGE_UUID2,
            },
            {
              value: 'mobile',
              label: 'Mobile',
              placeholder: 'Mobile',
              resolution: '375x665 pixels.',
              imageUUID: DUMMY_IMAGE_UUID3,
            },
          ].map((item) => (
            <div className="webeat-home-section-hero-block">
              <br />
              <UploadComponent
                format={'image'}
                token={auth.accessToken}
                uploadActions={[UPLOAD_ACTION_IMAGE]}
                materialType={typesOfSelectEnum.IMAGE}
                primaryEntityId={homeId || item.imageUUID}
                value={value[item.value]}
                key={value[item.value]}
                module="home"
                handleChangeField={(data: any) => {
                  handleChange(fieldConfig.key, {
                    ...value,
                    checked: value.checked,
                    [item.value]: data,
                  });
                  forceUpdate(Math.random());
                }}
                setValue={function (
                  valueItem?: string | null | undefined,
                ): void {
                  handleChange(fieldConfig.key, {
                    ...value,
                    checked: value.checked,
                    [item.value]: valueItem,
                  });
                  forceUpdate(Math.random());
                }}>
                <Button
                  className="uploadButton uploadButton__margin-top"
                  disabled={false}>
                  <Icon type="upload" />
                  <span>Upload {item.label}</span>
                </Button>
              </UploadComponent>
              <Text>Recommended resolution: {item.resolution}</Text>
              <br />
            </div>
          ))}
        <br />
      </Fragment>
    );
  }

  if (fieldConfig.type === 'upload-video') {
    if (value === null || value === undefined) {
      value = {
        checked: false,
        desktopLarge: null,
        desktop: null,
        mobile: null,
      };
    }
    const DUMMY_IMAGE_UUID = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384f';
    const DUMMY_IMAGE_UUID2 = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384c';
    const DUMMY_IMAGE_UUID3 = 'ce5f9eee-0cfc-43e4-aefa-a4b16dba384g';

    return (
      <Fragment>
        <Checkbox
          checked={value['checked']}
          onChange={(e) =>
            handleChange(fieldConfig.key, {
              desktopLarge: value.desktopLarge,
              checked: e.target.checked ? true : false,
              desktop: value.desktop,
              mobile: value.mobile,
            })
          }>
          {fieldConfig.label}
        </Checkbox>
        <br />
        {value['checked'] &&
          [
            {
              value: 'desktopLarge',
              label: 'Desktop Large',
              placeholder: 'Desktop Large',
              resolution: '2560x748 pixels.',
              imageUUID: DUMMY_IMAGE_UUID,
            },
            {
              value: 'desktop',
              label: 'Desktop',
              placeholder: 'Desktop',
              resolution: '1440x709 pixels.',
              imageUUID: DUMMY_IMAGE_UUID2,
            },
            {
              value: 'mobile',
              label: 'Mobile',
              placeholder: 'Mobile',
              resolution: '375x665 pixels.',
              imageUUID: DUMMY_IMAGE_UUID3,
            },
          ].map((item) => (
            <div className="webeat-home-section-hero-block">
              <br />
              <UploadComponent
                format={'video'}
                token={auth.accessToken}
                uploadActions={[UPLOAD_ACTION_VIDEO]}
                materialType={typesOfSelectEnum.VIDEO}
                primaryEntityId={homeId || item.imageUUID}
                value={value[item.value]}
                key={value[item.value]}
                module="home"
                handleChangeField={(data: any) => {
                  handleChange(fieldConfig.key, {
                    ...value,
                    checked: value.checked,
                    [item.value]: data,
                  });
                  forceUpdate(Math.random());
                }}
                setValue={function (
                  valueItem?: string | null | undefined,
                ): void {
                  handleChange(fieldConfig.key, {
                    ...value,
                    checked: value.checked,
                    [item.value]: valueItem,
                  });
                  forceUpdate(Math.random());
                }}>
                <Button
                  className="uploadButton uploadButton__margin-top"
                  disabled={false}>
                  <Icon type="upload" />
                  <span>Upload {item.label}</span>
                </Button>
              </UploadComponent>
              <Text>Recommended resolution: {item.resolution}</Text>
              <br />
            </div>
          ))}
        <br />
      </Fragment>
    );
  }

  if (fieldConfig.type === 'media-url') {
    if (value === null || value === undefined) {
      value = {
        checked: false,
        desktopLarge: null,
        desktop: null,
        mobile: null,
      };
    }

    const validateStatus = (fieldKey: string) => {
      return (
        !isValidUrl(value[fieldKey]) &&
        (isFieldTouchedOrFormSubmitted(fieldKey) ||
          parentProps.state.isHomeFormSubmited)
      );
    };

    return (
      <>
        <Checkbox
          checked={value['checked']}
          onChange={(e) =>
            handleChange(fieldConfig.key, {
              checked: e.target.checked ? true : false,
              desktopLarge: value['desktopLarge'],
              desktop: value['desktop'],
              mobile: value['mobile'],
            })
          }>
          {fieldConfig.label}
        </Checkbox>

        {value['checked'] && (
          <Fragment>
            <br />
            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={validateStatus('desktopLarge') ? 'error' : ''}
              required={fieldConfig.required}
              help={
                validateStatus('desktopLarge')
                  ? 'The URL format is not valid.'
                  : ''
              }
              label={'Desktop Large'}>
              <Input
                value={value['desktopLarge']}
                onBlur={() => markFieldAsTouched('desktopLarge')}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    desktopLarge: e.target.value,
                    desktop: value['desktop'],
                    mobile: value['mobile'],
                  })
                }
                placeholder={'Desktop Large'}
              />
            </FormItem>

            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={validateStatus('desktop') ? 'error' : ''}
              required={fieldConfig.required}
              help={
                validateStatus('desktop') ? 'The URL format is not valid.' : ''
              }
              label={'Desktop'}>
              <Input
                value={value['desktop']}
                onBlur={() => markFieldAsTouched('desktop')}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    desktopLarge: value['desktopLarge'],
                    desktop: e.target.value,
                    mobile: value['mobile'],
                  })
                }
                placeholder={'Desktop'}
              />
            </FormItem>

            <FormItem
              className="webeat-home-section-hero-block"
              colon={false}
              validateStatus={validateStatus('mobile') ? 'error' : ''}
              required={fieldConfig.required}
              help={
                validateStatus('mobile') ? 'The URL format is not valid.' : ''
              }
              label={'Mobile'}>
              <Input
                value={value['mobile']}
                onBlur={() => markFieldAsTouched('mobile')}
                onChange={(e) =>
                  handleChange(fieldConfig.key, {
                    checked: value['checked'],
                    desktopLarge: value['desktopLarge'],
                    desktop: value['desktop'],
                    mobile: e.target.value,
                  })
                }
                placeholder={'Mobile'}
              />
            </FormItem>
            <br />
          </Fragment>
        )}
      </>
    );
  }

  if (fieldConfig.type === 'text-alignment' && contentState[ENABLE_BANNER]) {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    return (
      <Radio.Group
        onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
        value={value || 'left'}>
        <Radio value={'left'} style={radioStyle}>
          Left aligned (Desktop), up aligned (Mobile)
        </Radio>
        <Radio value={'center'} style={radioStyle}>
          Center aligned (Desktop), down aligned (Mobile)
        </Radio>
      </Radio.Group>
    );
  }

  if (fieldConfig.type === 'header-type') {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };

    if (fieldConfig.key === 'headerType' && value === undefined) {
      handleChange(fieldConfig.key, 'transparent');
    }

    return (
      <Radio.Group
        onChange={(e) => handleChange(fieldConfig.key, e.target.value)}
        value={value || 'transparent'}>
        <Radio value={'transparent'} style={radioStyle}>
          Transparent (default)
        </Radio>
        <Radio value={'solid'} style={radioStyle}>
          Solid
        </Radio>
      </Radio.Group>
    );
  }

  if (fieldConfig.type === 'text-color' && contentState[ENABLE_BANNER]) {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        className="webeat-home-section-hero-block"
        dropdownMatchSelectWidth={false}
        value={value || 'bg.grey'}
        loading={!context || !context.combos || !context.combos.colors}
        placeholder={fieldConfig.placeholder}
        onSelect={(value: any) => {
          handleChange(fieldConfig.key, value);
        }}>
        {context.combos.colors?.map((option: any) => (
          <Select.Option
            key={option.value.toString()}
            value={option.value.toString()}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  background: option.colorCode,
                  marginRight: 10,
                }}></div>
              {option.description}
            </div>
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (fieldConfig.type === 'button' && contentState[ENABLE_BANNER]) {
    return (
      <Select
        showSearch
        optionFilterProp="children"
        className="webeat-home-section-hero-block"
        dropdownMatchSelectWidth={false}
        value={value || 'primary'}
        loading={!context || !context.combos || !context.combos.buttons}
        placeholder={fieldConfig.placeholder}
        onSelect={(value: any) => {
          handleChange(fieldConfig.key, value);
        }}>
        {context.combos.buttons?.map((option: any) => (
          <Select.Option
            key={option.value.toString()}
            value={option.value.toString()}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {option.description}
            </div>
          </Select.Option>
        ))}
      </Select>
    );
  }

  return null;
};
